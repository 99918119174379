.footer {
  color: white;
  font-size: 1.3rem;
  padding: 20px;
  max-width: 980px;
  margin: 0 auto;
}

.footer-social {
  display: flex;
  margin-left: 10px;
}

.social-icons {
  margin-right: 15px;
  margin-bottom: 5px;
}

.social-icons:hover {
  cursor: pointer;
}

.footer-links {
  margin: 0 auto;
}

.footer-links-desktop,
.footer-links-mobile {
  margin: auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.footer-links-desktop li,
.footer-links-mobile li {
  flex: 0 0 25%;
  margin-bottom: 16px;
}

.footer-copyright {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .footer-links-desktop,
  .footer-social {
    display: none;
  }

  .footer-links-mobile li {
    flex: 0 0 50%;
  }

  .footer-copyright {
    margin: 20px 0 0 22px;
  }
}

@media screen and (min-width: 601px) and (max-width: 979px) {
  .footer-links-desktop,
  .footer-social {
    display: none;
  }

  .footer-links {
    margin: 0px 30px 0px 36px;
  }

  .footer-copyright {
    margin-left: 36px;
  }
}

@media screen and (min-width: 980px) {
  .footer-links-mobile {
    display: none;
  }
}
