.row {
    position: relative;
    z-index: 10;
    margin-left: var(--edge-space);
    margin-bottom: var(--edge-space);
}

h2 {
    font-size: 4.5rem;
    color: #E5E5E5;
    margin-right: 30px;
}

.row-slider {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE 11 */
}

.row-slider::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 600px) {
    .row {
        margin-left: 26px;
    }

    h2 {
        font-size: 2.4rem;
    }
}

@media screen and (min-width: 601px) and (max-width: 979px) {
    h2 {
        font-size: 3rem;
    }
  }