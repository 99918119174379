.banner {
  position: relative;
  display: block;
  z-index: 0;
  margin-bottom: 20px;
  padding-bottom: 40%;
}

.banner-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 56.25vw;
  z-index: -10;
  background-size: cover;
  background-position: center;
}

.banner-vignette {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 56.25vw;
  background-image: linear-gradient(
    to bottom,
    rgba(20, 20, 20, 0) 0,
    rgba(20, 20, 20, 0.15) 15%,
    rgba(20, 20, 20, 0.35) 29%,
    rgba(20, 20, 20, 0.58) 44%,
    #141414 90%,
    #141414 100%
  );
  z-index: -5;
}

.banner-description {
  position: absolute;
  bottom: 35%;
  left: var(--edge-space);
  width: 36%;
  color: #e5e5e5;
  line-height: 1.2;
  cursor: default;
  min-height: 15.2vw;
  z-index: 5;
}

.banner-title {
  font-size: 4.8rem;
  margin: 0;
}

.banner-message {
  font-size: 3rem;
  margin: 25px 0;
  font-weight: 700;
}

.banner-synopsis {
  font-size: 2.4rem;
}

button {
  color: black;
  border: none;
  padding: 13px 40px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  opacity: 0.8;
}

.watch-button {
  background-color: rgba(255, 255, 255, 1);
}

.watch-button:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

.info-button {
  background-color: rgba(109, 109, 110, 0.7);
  margin-left: 15px;
  color: white;
}

.info-button:hover {
  background-color: rgba(109, 109, 110, 0.4);
}

/* mobile sizes with max-width of 600px*/
@media screen and (max-width: 600px) {
  .banner {
    min-height: 230px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .banner-img,
  .banner-vignette {
    min-height: 230px;
    z-index: -5;
  }

  .banner-description {
    position: static;
    width: 80%;
    margin: 100px 30px 0px 30px;
  }

  .banner-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .banner-message {
    font-size: 1.6rem;
    margin: 5px 0;
    margin-bottom: 20px;
  }

  .banner-synopsis {
    display: none;
  }

  .watch-button,
  .info-button {
    padding: 7px 15px;
  }
}

/* tablet sizes of up to 768px */
@media screen and (min-width: 601px) and (max-width: 979px) {
  .banner-title {
    font-size: 3.2rem;
    margin-top: 30px;
  }

  .banner-description {
    width: 50%;
    position: static;
    margin: 100px 30px 0px 60px;
  }

  .banner-message {
    font-size: 2rem;
  }

  .banner-synopsis {
    display: none;
  }

  .watch-button,
  .info-button {
    padding: 10px 20px;
  }
}

/*desktop sizes past 980 px */
@media screen and (min-width: 980px) and (max-width: 1199px) {
  .banner-title {
    font-size: 3.6rem;
  }

  .banner-message {
    font-size: 2rem;
  }

  .banner-synopsis {
    font-size: 1.6rem;
  }
}
